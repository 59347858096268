import {
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/app/api/apiSlice'

const userWaitListsAdapter = createEntityAdapter({})

const initialState = userWaitListsAdapter.getInitialState()

export const userWaitListsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserWaitLists: builder.query({
            query: () => ({
                url: '/userWaitLists/getUserWaitLists',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            }),
            transformResponse: responseData => {
                const loadedUserWaitLists = responseData.map(userWaitList => {
                    userWaitList.id = userWaitList._id
                    return userWaitList
                })
                return userWaitListsAdapter.setAll(initialState, loadedUserWaitLists)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        {type: 'UserWaitList', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'UserWaitList', id}))
                    ]
                } else {
                    return [ { type: 'UserWaitList', id: 'LIST'}]
                }
            },
        }),
        createNewUserWaitList: builder.mutation({
            query: initialUserWaitList => ({
                url: '/userWaitLists/createNewUserWaitList',
                method: 'POST',
                body: {...initialUserWaitList,}
            }),
            invalidatesTags: [
                { type: 'UserWaitList', id: "LIST" }
            ] 
        }),
        updateUserWaitList: builder.mutation({
            query: initialUserWaitList => ({
                url: '/userWaitLists/updateUserWaitList',
                method: 'PATCH',
                body: {...initialUserWaitList,}
            }),
            invalidatesTags: (result, error, arg) => [
                {type: 'UserWaitList', id: arg.id },
                { type: 'UserWaitList', id: 'LIST' }
            ],
        }),
        deleteUserWaitList: builder.mutation({
            query: ({id}) => ({
                url: '/userWaitLists/deleteUserWaitList',
                method: 'DELETE',
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'UserWaitList', id: arg.id}
            ]
        }),
    }),
})

export const {
    useGetUserWaitListsQuery,
    useCreateNewUserWaitListMutation,
    useUpdateUserWaitListMutation,
    useDeleteUserWaitListMutation,
} = userWaitListsApiSlice

// returns the query result object
export const selectUserWaitListResult = userWaitListsApiSlice.endpoints.getUserWaitLists.select()

// create memoized selector
const selectUserWaitListsData = createSelector(
    selectUserWaitListResult,
    userWaitListResult => userWaitListResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUserWaitLists,
    selectById: selectUserWaitListById,
    selectIds: selectUserWaitListIds,
} = userWaitListsAdapter.getSelectors(state => selectUserWaitListsData(state) ?? initialState)