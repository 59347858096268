import React, { useState } from "react";
import EtGlobal from "@/assets/images/EtGlobal.png";
import { VortexDemoSecond } from "@/components/VortexDemoSecond.js";
import { BackgroundGradient } from "@/components/ui/BackgroundGradient";
import DynamicText from "@/components/DynamicText.jsx";
import { TextGenerateEffect } from "@/components/ui/TextGenerate.js";
import EtWaitlistModal from "@/components/modal/EtWaitListModal";
import EtNavbar from "@/components/navigation/EtNavbar";
import EtFooter from "@/components/navigation/EtFooter";

const EtWebHome = () => {
  // First Created - 27 Mar 2025
  // Author(s) - Corey Marsh, Atul Pandey
  // Inherited from Corey Marsh's original etlanding252 project and subsequently modified by Atul

  // 1 - Set modal state variables
  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false);
  
  return (
    <div className="h-screen">
        
        {/* Bottom Layer: Black Background */}
        
        <div 
          className="absolute inset-0  bg-black opacity-100 z-[-3]">
        </div>
        
        {/* Next Layer: Fullscreen VortexDemoSecond Overlay */}
        
        <div className="absolute inset-0 z-[-1]">
          <VortexDemoSecond />
        </div>

        {/* Logo Panel Layout */}
        
        <div className="max-h-screen">
          <EtNavbar onJoinWaitlistClick={() => setWaitlistModalOpen(true)}/>
          <div className="flex justify-center items-center">
            
            <div className="w-2/3 pl-10 pr-5">
                  <div className="flex justify-center items-center my-5 py-5" >
                  <BackgroundGradient>
                    <TextGenerateEffect>
                      Are you ready to...
                    </TextGenerateEffect>
                  </BackgroundGradient>
                  </div>
                  <div className="p-2">
                    <h1 className="text-white text-[3rem] xl:text-6xl leading-tight relative z-10 mb-4">
                      <p className="my-1 py-2 font-PoppinsBlack leading-4">
                      <span
                        className="my-1 py-1 underline"
                        style={{
                          color: "#F66A2E",
                          textShadow:
                            "0 0 1px #F66A2E, 0 0 1px #F66A2E, 0 0 5px #F66A2E",
                        }}
                      >
                        UN
                      </span>
                      <span className="ml-[4px]">LOCK</span> <span className="mx-1 px-1">YOUR</span> LIMITLESS
                      </p>
                        <div className="my-4 py-4">
                          <BackgroundGradient className="text-center">
                          <DynamicText/>
                        </BackgroundGradient>
                        </div>
                    </h1>
                  </div>

            </div>

            <div className="w-1/3 flex justify-center items-center pr-10">
              {/* Right Panel (Centered Video) */}
                <img
                  src={EtGlobal}
                  alt="EtGlobal Logo"
                  className="w-full h-auto"
                />
            </div>
          </div>
        </div>
        <EtWaitlistModal
          open={waitlistModalOpen}
          onClose={() => setWaitlistModalOpen(false)}
        />
        <EtFooter />
    </div>
  )
}

export default EtWebHome