const EtFooter = () => {
  // First Created - 27 Mar 2025
  // Author(s) - Corey Marsh, Atul Pandey
  // Inherited from Corey Marsh's original etlanding252 project and subsequently modified by Atul
    
  return (
      <footer className="bg-black text-gray-200 p-4 py-10 px-10 my-5">
        <p className="text-[9px] xxs:text-xs text-gray-500 font-poppins">
          &copy; {new Date().getFullYear()} Eternity Solutions Corp. All rights reserved.
        </p>
      </footer>
    );
  }

  export default EtFooter