import { Routes, Route } from "react-router-dom"

import EtWeb from '@/pages/landing/EtWeb'
import EtUserWaitList from "@/pages/admin/userWaitLists/EtUserWaitList";
import Login from '@/features/auth/Login'
import SignUpNewUserForm from '@/features/users/SignUpNewUserForm'
import PersistLogin from "@/features/auth/PersistLogin"
import RequireAuth from "@/features/auth/RequireAuth"
import { ROLES } from "@/config/role" 
import Prefetch from "@/features/auth/Prefetch"

const App = () => {
  // First Created - 27 Mar 2025
  // Author - Atul Pandey
  // Inherited from Corey Marsh's original etlanding252 project and subsequently modified by Atul
  // Updated - 28 Mar 2025 - adjustment for screen sizes
  // Updated - 31 Mar 2025 - moved the main landing page code to EtWeb
  // Updated - 31 Mar 2025 - added Routes and Route
  // Updated - 31 Mar 2025 - added Public and Protected Routes
  // Updated - 31 Mar 2025 - added Admin User Login

  
  return (
    <Routes>
      <Route path="/">

        {/* Public Routes */}
          <Route index element={<EtWeb />} />
          <Route path="login" element={<Login />} />
           <Route path="signup" element={<SignUpNewUserForm />} />
           {/* <Route path="userWaitList" element={<EtUserWaitList />} /> */}
          

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
            <Route
              element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
            >
              <Route element={<Prefetch />}>
              
                    <Route path="userWaitList" element={<EtUserWaitList />} />
              
              </Route> {/* End of Protected routes  - prefetch */}

            </Route>{/* End of Protected routes  - require auth*/}
        </Route>{/* End of Protected routes - persist login*/}



      </Route>
    </Routes>
  );
}

export default App