import React, {useState, useEffect} from 'react'
import EtWebHome from './EtWebHome'
import EtWebHomeMobile from './EtWebHomeMobile'
 
const EtWeb = () => {
  
  // First Created - 31 Mar 2025
  // Author - Atul Pandey
  // Inherited from Corey Marsh's original etlanding252 project and subsequently modified by Atul 
  // First created inside App.jsx on 27 Mar 2025
  // Original Update Trail from App.jsx
  // Updated - 28 Mar 2025 - adjustment for screen sizes
  // Updated - 31 Mar 2025 - moved the main landing page code to EtWeb
  // Updated - 31 Mar 2025 - added Routes and Route

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreen = () => setIsMobile(window.innerWidth < 1100);

    checkScreen(); // Initial check
    window.addEventListener('resize', checkScreen); // Responsive
    return () => window.removeEventListener('resize', checkScreen);
  }, []);

  return (
    <div>
      {isMobile ? <EtWebHomeMobile /> : <EtWebHome />}
    </div>
  );
}

export default EtWeb