import Logo from "@/assets/images/EtLogo1.png";

const EtNavbar = ({ onJoinWaitlistClick, onMagicClick }) => {
  // First Created - 27 Mar 2025
  // Author(s) - Corey Marsh, Atul Pandey
  // Inherited from Corey Marsh's original etlanding252 project and subsequently modified by Atul
   
  return ( 
      
        <nav className="w-full px-4 py-3 flex justify-between items-center relative">
      
          {/* Logo (Left-aligned) */}
          <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
            <img
              className="h-12 w-12 md:h-20 md:w-20"
              src={Logo}
              alt="Logo"
            />
          </div>

          {/* Centered CTA */}
          <div className="mx-auto md:ml-[20rem] lg:ml-[17rem] xl:ml-[24rem] md:mr-[0rem]">
            <div
              className="flex justify-center items-center cursor-pointer"
              onClick={onJoinWaitlistClick}
            >
              <p className="text-xs sm:text-sm md:text-lg text-orange-600 border border-gray-600 hover:text-white px-4 py-2 font-semibold font-poppins rounded-full hover:border-orange-600 hover:bg-orange-600 transition">
                Join the waitlist
              </p>
            </div>
          </div>

        </nav>

      // <nav className="px-5 py-3 grid sm:grid-cols-5 grid-cols-2 sm:justify-between justify-start items-center">
         
      //   <div className="col-span-1 flex justify-start items center">
      //     {/* <img className="h-24 w-24" src={Trinity} alt="Logo" /> */}
      //     <div className="flex justify-center items-center">
      //         <img className="h-12 w-12 md:h-24 md:w-24" src={Logo} alt="Logo" />
      //     </div>
            
      //   </div>
      //   <div className="col-span-1">
      //     <div 
      //       className="flex sm:justify-end justify-start items-center md:h-24 w-48 cursor-pointer"
      //       onClick={onJoinWaitlistClick}
      //     >
      //         <p className="text-xs sm:text-sm md:text-lg text-orange-600 border border-gray-600 hover:text-white mr-6 p-2 font-semibold font-poppins rounded-full hover:border hover:border-orange-600 hover:bg-orange-600"> Join the waitlist</p>
      //     </div> 
      //   </div>
      //   {/* <div className="hidden md:flex space-x-4">
      //     <MagicButton onClick={onMagicClick} />
      //   </div> */}
        
      // </nav>
      
    );
  }

  export default EtNavbar