import React, { useState } from "react";
import EtGlobal from "@/assets/images/EtGlobal.png";
import { VortexDemoSecond } from "@/components/VortexDemoSecond.js";
import { BackgroundGradient } from "@/components/ui/BackgroundGradient";
import DynamicText from "@/components/DynamicText.jsx";
import { TextGenerateEffect } from "@/components/ui/TextGenerate.js";
import EtWaitlistModal from "@/components/modal/EtWaitListModal";
import EtNavbar from "@/components/navigation/EtNavbar";
import EtFooter from "@/components/navigation/EtFooter";

const EtWebHomeMobile = () => {
  // First Created - 28 Mar 2025
  // Author - Atul Pandey

  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false);

  return (
    <div className="min-h-screen relative overflow-x-hidden">
      
      {/* Backgrounds */}
      <div className="absolute inset-0 bg-black opacity-100 z-[-3]" />
      <div className="absolute inset-0 z-[-1]">
        <VortexDemoSecond />
      </div>

      {/* Navbar */}
      <EtNavbar onJoinWaitlistClick={() => setWaitlistModalOpen(true)} />

      {/* Mobile Content */}
      <div className="flex flex-col justify-center items-center text-center px-4 py-6 space-y-6">
        
        <BackgroundGradient>
          <TextGenerateEffect>
            Are you ready to...
          </TextGenerateEffect>
        </BackgroundGradient>

        <h1 className="text-white text-4xl sm:text-5xl font-PoppinsBlack leading-tight">
          <span className="underline text-orange-600" style={{ textShadow: "0 0 1px #F66A2E, 0 0 1px #F66A2E, 0 0 5px #F66A2E" }}>
            UN
          </span>
          <span className="ml-[2px]">LOCK</span> <span className="mx-1">YOUR</span> LIMITLESS
        </h1>

        <BackgroundGradient className="text-center">
          <DynamicText />
        </BackgroundGradient>

        <img src={EtGlobal} alt="EtGlobal Logo" className="w-3/4 h-auto rounded-xl shadow-md mt-6" />
      </div>

      {/* Modal */}
      <EtWaitlistModal
        open={waitlistModalOpen}
        onClose={() => {
          setWaitlistModalOpen(false);
          window.location.reload();
        }}
      />

      {/* Footer */}
      <EtFooter />
    </div>
  );
};

export default EtWebHomeMobile;