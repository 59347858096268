import React, { useState } from "react";
import { useCreateNewUserWaitListMutation } from '@/features/userWaitLists/userWaitListsApiSlice'; // 27 Mar 2025



const EtWaitlistModal = ({ open, onClose }) => {
  // First Created - 24 Mar 2025
  // Author(s) - Atul Pandey
  // Updated - 27 Mar 2025 - changed to RTK and own server backend instead of formsubmit.co infra
    
  // 1 - Set state variables
  const [submitted, setSubmitted] = useState(false);

  // 2 - Set the RTK mutation for creating new waitlists
  const [createNewUserWaitList] = useCreateNewUserWaitListMutation();
  const [customMessage, setCustomMessage] = useState('');
  
  // 3 - set form data
  const [formData, setFormData] = useState({
      name: "",
      email: "",
      org: "",
      role: "",
    });

  // 4 - Handle Change
  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  
  // 5 - Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {

      await createNewUserWaitList({
        name: formData.name,
        email: formData.email,
        userOrgName: formData.org,
        userOrgRole: formData.role,
      }).unwrap();

    setCustomMessage(`Thank you for joining!`);
    setSubmitted(true);

    // console.log('Waitlist request submitted...')

    } catch (err) {
      if (err?.status === 409 && err?.data?.message) {
        setCustomMessage(err.data.message); // Message from backend
        setSubmitted(true);
      } else {
        console.error('Submission error:', err);
      }
    }
};

if (!open) return null;
  
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50 font-poppins">
        {/* <div className="rounded-lg p-8 w-full max-w-lg relative bg-black border border-gray-800 shadow-xl shadow-orange-800"> */}
        <div className="rounded-lg p-6 sm:p-8 w-[90%] max-w-lg sm:w-full relative bg-black border border-gray-800 shadow-xl shadow-orange-800">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-2xl"
          >
            <p className="text-gray-400 hover:text-orange-600 cursor-pointer">&times;</p>
          </button>
  
          {!submitted ? (
            <>
              <h2 className="text-2xl mb-4 font-bold text-gray-500">
                Join the Waitlist
              </h2>
              <p className="mb-4 text-sm text-gray-500">
                Let us know who you are and we’ll be in touch.
              </p>
              <form onSubmit={handleSubmit} className="space-y-4">
                <input
                  name="name"
                  placeholder="Your Name"
                  required
                  className="w-full border border-gray-800 rounded-full px-4 py-2 bg-gradient-to-r from-black to-black via-gray-800 text-white"
                  onChange={handleChange}
                />
                <input
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  required
                  className="w-full border border-gray-800 rounded-full px-4 py-2 bg-gradient-to-r from-black to-black via-gray-800 text-white"
                  onChange={handleChange}
                />
                <input
                  name="org"
                  placeholder="Organization Name"
                  className="w-full border border-gray-800 rounded-full px-4 py-2 bg-gradient-to-r from-black to-black via-gray-800 text-white"
                  onChange={handleChange}
                />
                <input
                  name="role"
                  placeholder="Your Role"
                  className="w-full border border-gray-800 rounded-full px-4 py-2 bg-gradient-to-r from-black to-black via-gray-800 text-white"
                  onChange={handleChange}
                />
                <button
                  type="submit"
                  // className="w-full bg-orange-600 text-white py-2 rounded hover:bg-orange-700 transition"
                  className="w-full rounded-full bg-gradient-to-r from-orange-800 to-orange-800 via-orange-600 text-white py-2 cursor-pointer hover:bg-gradient-to-r hover:from-red-600 hover:to-red-600 hover:via-orange-800 transition"
                >
                  <p className="text-white">Submit</p>
                </button>
              </form>
            </>
          ) : (
            <div className="text-center">
              <h2 className="text-xl text-orange-600 mb-2">
                  {customMessage}
              </h2>
              <p className="text-gray-400 mb-4">
                We’ll be in touch shortly. You can close this window now.
              </p>
              <button
                // onClick={onClose}
                onClick={() => {
                  onClose();
                  window.location.reload(); // Refresh the page on close
                }}
                className="mt-2 px-4 py-2 bg-orange-600 text-white rounded hover:bg-orange-700"
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    );
}

export default EtWaitlistModal