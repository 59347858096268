import React from "react";
import { Vortex } from "../components/ui/Vortex";

export function VortexDemoSecond() {
  return (
    (<div
      className="w-[calc(100%-4rem)] mx-auto rounded-md h-screen overflow-hidden">
      <Vortex
        backgroundColor="transparent"
        rangeY={500}
        particleCount={20}
        baseHue={30}
        className="flex items-center flex-col justify-center px-2 md:px-10  py-4 w-full h-full">
      </Vortex>
    </div>)
  );
}
