// apiSlice.js - to connect front end calls to the backend via RTK
// Author - Atul Pandey
// Updated - 18 Feb 2025 - refinement for handling 401 errors

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials } from '@/features/auth/authSlice'

const baseUrlString = process.env.REACT_APP_SERVER_URL

const baseQuery = fetchBaseQuery({
    baseUrl: baseUrlString,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token
        // console.log('token:', token)

        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    
    let result = await baseQuery(args, api, extraOptions)

    // If you want, handle other status codes, too
    // if (result?.error?.status === 403) {
    if (result?.error?.status === 401 || result?.error?.status === 403) {   // 18 Feb 2025
        // console.log('sending refresh token')
        console.log('Access token expired, attempting refresh...')          // 18 Feb 2025

        // send refresh token to get new access token 
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)

        if (refreshResult?.data) {

            // store the new token 
            api.dispatch(setCredentials({ ...refreshResult.data }))

            // retry original query with new access token
            result = await baseQuery(args, api, extraOptions)
        } else {

            if (refreshResult?.error?.status === 403) {
                refreshResult.error.data.message = "Your login has expired. "
            }
            return refreshResult
        }
    }

    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Note', 'User', 'QuestionSet', 'Question', 'Investigation'],
    endpoints: builder => ({})
})