import React, { useState, useEffect } from "react";

function DynamicText() {
  const words = [
    // { text: "POSSIBILITIES", color: "#f2bf35" }, 
    // { text: "POTENTIAL", color: "#f2bf35" },     
    // { text: "POWER", color: "#f2bff35" }, 
    { text: "POSSIBILITIES", color: "#F66A2E" }, 
    { text: "POTENTIAL", color: "#F66A2E" },     
    { text: "POWER", color: "#F66A2E" }, 
    // { text: "POSSIBILITIES", color: "#000" }, 
    // { text: "POTENTIAL", color: "#F66A2E" },     
    // { text: "POWER", color: "#fff" }, 
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % words.length);
    }, 3000); // Change word every 3 seconds
    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <span
      style={{ color: words[currentIndex].color, }}
      className="font-PoppinsBlack text-4xl sm:text-5xl"
    >
      {words[currentIndex].text}
    </span>
  );
}

export default DynamicText;
