import React, { useState, useEffect} from 'react'
import { useNavigate } from "react-router"
import useTitle from "@/hooks/useTitle"
import { PlusIcon, MinusIcon, TrashIcon, PencilSquareIcon,  } from '@heroicons/react/24/outline'
import { UserIcon, PhoneArrowUpRightIcon, AtSymbolIcon, BuildingOfficeIcon, ClockIcon } from '@heroicons/react/24/solid'
import EtLogo from '@/assets/images/EtLogo2.png'
import { useGetUserWaitListsQuery } from '@/features/userWaitLists/userWaitListsApiSlice'
import moment from "moment";
import useAuth from '@/hooks/useAuth';
import { useSendLogoutMutation } from "@/features/auth/authApiSlice"
import EtLogoutIcon1SVGComp from "@/assets/img/icons/svgComp/EtLogoutIcon1SVGComp" 

const EtUserWaitList = () => {
  
  // First Created - 31 Mar 2025
  // Author - Atul Pandey
  // Purpose - For Et Web Admins and Client Excellence Team to view and act on waitlist requests received from EtWeb
  
  // 0 - Set Page Title
  useTitle('Et - User Waitlist')

  // 1 - Set State Variables
  const navigate = useNavigate()

  const { username, name, status,isAdmin } = useAuth() // Extract username & name
  // console.log('username', username)
  // console.log('name', name)
  // console.log('status', status)
  // console.log('isAdmin', isAdmin)

  // 2 - RTK Data Queries and Mutations
  const { data:userWaitListsData, isLoading: isSyncing, error, refetch } = useGetUserWaitListsQuery();

  const [sendLogout, {
    isLoading,
    isError,
    error: logOutError,
    }] = useSendLogoutMutation()

  const extractEntitiesFromAdapter = (dataAdapter) => {
    if (!dataAdapter || !dataAdapter.ids || !dataAdapter.entities) return [];
    return dataAdapter.ids.map((id) => dataAdapter.entities[id]);
  };

  const waitList = extractEntitiesFromAdapter(userWaitListsData);
//   console.log('userWaitLists', waitList)

// 3 - Handle Logout
const handleLogout = async () => {
  try {
    await sendLogout().unwrap();
    navigate('/login')
  } catch (err) {
    console.error('Logout failed:', err);
  }
};


  return (
    <div className='m-2 p-2 bg-gray-200 rounded font-poppins'>
        <div className='flex justify-start items-center xs:m-4 xs:p-4 m-2 p-2'>
            <div className='h-8 w-8 xs:w-12 xs:h-12 rounded-full flex justify-center items-center'>
                <img src={EtLogo} alt="Eternity" className='xs:w-10 xs:h-6 w-7 h-4 ' />
            </div>
             <p className='font-bold text-md xs:text-2xl sm:text-4xl mx-2 px-2'>Guest WaitList</p>
             <div className='flex justify-center items-center rounded-full h-8 w-8 sm:w-12 sm:h-12 border border-orange-600 bg-orange-50'>
                <p className='m-4 p-4 font-bold text-lg sm:text-2xl'>{waitList?.length}</p>
             </div>
             <div className='relative group justify-center items-center cursor-pointer px-5'  onClick={handleLogout}> 
                  <div className='flex justify-center items-center rounded-full w-4 h-4 bg-gradient-to-r from-orange-800 to-orange-800 via-orange-600 shadow-xl shadow-gray-600'>
                  <EtLogoutIcon1SVGComp className='h-3 w-3 text-white'/>
                  </div>
                  {/* Tooltip */}
                  <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-xs rounded py-1 px-2 z-10">
                      Logout
                  </div>
              </div>
        </div>
       

        <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 justify-start items-center xs:px-1 xs:mx-1 xl:px-3 xl:mx-3'>
          {waitList.length > 0 && waitList
          ?.slice()
          .sort(
            (a, b) =>
                new Date(a.createdAt) - new Date(b.createdAt)
        ) // Sort in descending order
          ?.map((item, itemIndex) => (
              <div key={item._id + itemIndex} className='bg-white m-1 p-1 rounded shadow-lg shadow-gray-600'>
                  
                    <div className='flex justify-start items-center m-1 p-1'>
                        <div>
                            <p className='font-bold text-gray-400 mx-1 px-1'>{itemIndex + 1}.</p>
                        </div>
                        <div>
                            <UserIcon className='text-orange-600 h-6 w-6' />
                        </div>
                        <div>
                            <p className='text-xs text-gray-800 mx-1 px-1 font-bold'>{item.name}</p>
                            <p className='text-[10px] text-gray-400 mx-1 px-1 italic'>{item.userOrgRole}</p>
                        </div>
                    </div>
                    <div className='flex justify-start items-center m-1 p-1 border-t'>
                            <div>
                            <BuildingOfficeIcon className='text-orange-600 h-4 w-4' />
                            </div>
                        <div><p className='text-xs text-gray-400 m-1 p-1 font-bold'>Org :</p></div>
                        <div><p className='text-xs text-gray-600 m-1 p-1'>{item.userOrgName}</p></div>
                    </div>
                    <div className='flex justify-start items-center m-1 p-1 border-t'>
                            <div>
                            <AtSymbolIcon className='text-orange-600 h-4 w-4' />
                            </div>
                            <div><p className='text-xs text-gray-400 m-1 p-1 font-bold'>Email :</p></div>
                            <div><p className='text-[10px] text-gray-600 mx-1 px-1'>{item.email}</p></div>  
                    </div>
                    <div className='flex justify-start items-center m-1 p-1 border-t'>
                            <div>
                            <ClockIcon className='text-lime-600 h-4 w-4' />
                            </div>
                            <div><p className='text-xs text-gray-400 m-1 p-1 font-bold'>Requested On :</p></div>
                            <div><p className='text-[10px] text-gray-600 mx-1 px-1'>{moment(item.createdAt).format('DD MMM YYYY, hh:mm A')}</p></div>  
                    </div>
              </div>
            ))}
          </div>
      </div>
  )
}

export default EtUserWaitList